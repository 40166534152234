import * as Sentry from '@sentry/react';

export default () => {
  Sentry.init({
    dsn: 'https://b041e107d1244d2dbdc85259abb97bfb@o184447.ingest.us.sentry.io/1866781',
    release: `${__VERSION__}`,
    integrations: [
      Sentry.browserTracingIntegration({
        tracePropagationTargets: [/^\//],
      }),
      Sentry.breadcrumbsIntegration({
        console: true
      }),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'SyntaxError: Unexpected token \'<\'',
      'Error: Method not found',
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.5,
  });

  return store => next => action => {
    try {
      return next(action);
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('redux_action', action.type);
        scope.setExtra('redux_state', store.getState());
        Sentry.captureException(error);
      });
      throw error;
    }
  };
};
