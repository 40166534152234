import { newInstance } from 'localization';

export const TRANSPORT_DIRECTION_MAP = {
  OUTBOUND: {
    get text() {
      return newInstance.t('CLAIM:OUTBOUND');
    },
    value: 0
  },
  INBOUND: {
    get text() {
      return newInstance.t('CLAIM:INBOUND');
    },
    value: 1
  },
  TRANSITIONAL: {
    get text() {
      return newInstance.t('CLAIM:TRANSITIONAL');
    },
    value: 2
  },
};
export const TRANSPORT_DIRECTION_OPTIONS = [
  TRANSPORT_DIRECTION_MAP.OUTBOUND,
  TRANSPORT_DIRECTION_MAP.INBOUND,
  TRANSPORT_DIRECTION_MAP.TRANSITIONAL,
];
export const TRANSPORT_TYPE_OPTIONS = [
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:AIRPLANE');
    },
    value: 1
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:BUS');
    },
    value: 2
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:RAILROAD');
    },
    value: 3
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:RAILROAD_AND_BUS');
    },
    value: 4
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:OTHER');
    },
    value: 5
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:AIRPLANE_ECONOMY');
    },
    value: 6
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:AIRPLANE_BUSINESS');
    },
    value: 7
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:RAILROAD_RESERVED');
    },
    value: 8
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:RAILROAD_COMPARTMENT');
    },
    value: 9
  },
  {
    get text() {
      return newInstance.t('TRANSPORT_TYPE:SHIP');
    },
    value: 10
  },
];
