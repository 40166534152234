import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

import { prepareBookingsSearchToAPI } from './helpers';

const connectedBookingMapper = R.map(({ opClaimId, ...rest }) => ({
  op_claim_id: opClaimId,
  ...rest
}));

const bookingMapper = R.pipe(
  R.over(
    R.lensPath(['tour', 'country']),
    R.when(
      Boolean,
      R.over(R.lensProp('translations'), R.indexBy(R.prop('locale')))
    )
  ),
  ({ connected_bookings: connectedBookings, total_views: totalViews, total_views_details: totalViewsDetails, updates, operator, ...rest }) => ({
    connectedBookings: connectedBookingMapper(connectedBookings ?? []),
    totalViews,
    totalViewsDetails,
    lastSuccessUpdate: R.pipe(
      R.head,
      R.when(
        Boolean,
        R.prop('created')
      )
    )(updates ?? []),
    operator: R.over(
      R.lensProp('schedules'),
      R.ifElse(
        Boolean,
        R.map(({ schedule_items: scheduleItems, ...operatorData }) => ({
          scheduleItems,
          ...operatorData
        })),
        R.always([])
      )
    )(operator ?? {}),
    ...rest
  })
);

export const getBookings = (token, params = {}) => new BaseApiClass({
  url: 'bookings',
  method: 'GET',
  ...params,
})
  .setConvertQueryToServerSchema(prepareBookingsSearchToAPI)
  .setParseBodyToClientSchema(R.over(
    R.lensProp('bookings'),
    R.map(bookingMapper)
  ))
  ._load({ token });

export const getBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}',
  method: 'GET',
  ...params,
})
  .setParseBodyToClientSchema(bookingMapper)
  ._load({ token });

export const getDocuments = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/documents',
  method: 'GET',
  ...params,
})._load({ token });

export const getDocument = (params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/document/{:documentId}/download',
  method: 'GET',
  ...params,
});

export const getDocumentsTypes = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'bookings/{:bookingId}/documents/types',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = R.map(({ id: value, type: text }) => ({ value, text }));

  return instance._load({ token });
};

export const addDocuments = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'bookings/{:bookingId}/document',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = R.dissoc('Content-Type');

  return instance._load({ token });
};

export const updateDocument = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/document/{:documentId}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteDocument = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/document/{:documentId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
export const getBookingCash = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'bookings/{:bookingId}/cash',
    method: 'GET',
    ...params,
  });

  return instance._load({ token });
};

export const updateBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}',
  method: 'PUT',
  ...params,
})
  .setParseBodyToClientSchema(bookingMapper)
  ._load({ token });

export const createBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings',
  method: 'POST',
  ...params,
})._load({ token });

export const deleteBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const createClaimFromBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/bootstrap',
  method: 'POST',
  ...params,
})._load({ token });

export const fillClaimFromBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/fill/{:claimId}',
  method: 'PUT',
  ...params,
})
  .setParseBodyToClientSchema(bookingMapper)
  ._load({ token });
export const removeClaimFromBooking = (token, params = {}) => new BaseApiClass({
  url: 'claim/{:claimId}/bookings/{:bookingId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getBookingChanges = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/changes',
  method: 'GET',
  ...params,
})._load({ token });

export const markBookingViewed = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/viewed',
  method: 'PUT',
  successCode: 204,
  ...params,
})._load({ token });

export const getBookingsStat = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'bookings/stat',
    method: 'GET',
    ...params,
  });

  instance.convertQueryToServerSchema = prepareBookingsSearchToAPI;

  return instance._load({ token });
};
export const refreshBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/update',
  method: 'PUT',
  successCode: 204,
  ...params,
})._load({ token });
export const calculateEnd2EndBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/calculate',
  method: 'GET',
  ...params,
})._load({ token });
export const bookingEnd2EndBooking = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/book',
  method: 'PUT',
  ...params,
})._load({ token });
export const addBookingView = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/review',
  method: 'POST',
  ...params,
})._load({ token });
