import React, { memo } from 'react';

// assets
import DangerIcon from 'assets/icons/danger.svg';
import CheckIcon from 'assets/icons/check-icon.svg';
import CloseIcon from 'assets/icons/close.svg';

// styles
import cx from 'classnames';
import Styles from './styles.scss';

const alerts = {
  warning: DangerIcon,
  error: DangerIcon,
  success: CheckIcon,
};

const C = ({ content, type, className, children, small, onDismiss, style }) => {
  return (
    <div
      className={cx(Styles.root, { [Styles.small]: small }, Styles[type], className)}
      style={style}
    >
      { onDismiss && <div className={Styles.closeIcon} dangerouslySetInnerHTML={{ __html: CloseIcon }} onClick={onDismiss} /> }
      <div className={Styles.iconWrapper}>
        <div className={Styles.icon} dangerouslySetInnerHTML={{ __html: alerts[type] }} />
      </div>
      <div>
        { content ? content : children }
      </div>
    </div>
  );
};

C.defaultProps = {
  content: null,
  className: null,
  small: false,
  style: null,
  type: 'warning',
};

C.displayName = 'Alert';
export const Alert = memo(C);
