import { put, race, select, takeEvery, take } from 'redux-saga/effects';
import * as R from 'ramda';

import { getOperators } from 'bus/common/selectors';
import { commonActions } from 'bus/common/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { bootstrap, setIdentifier } from './actions';
import { BUS_TICKETS_BOOTSTRAP_UI } from './constants';

const BOOKING_OPERATOR_ID = 487;
function* bootstrapSaga() {
  yield put(registerUI(createUi({ loading: true }), BUS_TICKETS_BOOTSTRAP_UI));

  const shouldDownloadOperators = yield select(state => R.isEmpty(getOperators(state)));

  if (shouldDownloadOperators) {
    yield put(commonActions.getOperatorsMt({ with_credentials: true }));
  }

  const [getOperatorsSuccess] = shouldDownloadOperators ? yield race([
    take(commonActions.getOperatorsMtSuccess),
    take(commonActions.getOperatorsMtFail)
  ]) : [true];

  if (getOperatorsSuccess) {
    const operatorIdentifier = yield select(state => {
      const operators = getOperators(state);

      return R.pipe(
        R.find(R.propEq(BOOKING_OPERATOR_ID, 'id')),
        R.prop('tfCredentials'),
        R.find(({ sub_agent_identifier: identifier }) => identifier),
        R.prop('sub_agent_identifier')
      )(operators);
    });

    if (operatorIdentifier) {
      yield put(setIdentifier(operatorIdentifier));
    }
  }

  yield put(updateUI({ loading: false, completed: true }, BUS_TICKETS_BOOTSTRAP_UI));
}

export default function* () {
  yield takeEvery(bootstrap, bootstrapSaga);
}
