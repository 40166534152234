import BaseApiClass from 'api/BaseApiClass';
import * as R from 'ramda';
import { OTPUSK_AUCTION_URL } from './constants';

export const getAuctionOffers = (params = {}) => {
  const instance = new BaseApiClass({
    method: 'GET',
    outerResource: `${OTPUSK_AUCTION_URL}api/requests`,
    ...params,
  });

  instance.parseBodyToClientSchema = orders => R.map(
    order => R.over(R.lensProp('dateFrom'), R.prop('date'), order),
    orders
  );

  return instance._load({ token: null });
};
