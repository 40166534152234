import { createSelector } from 'reselect';
import * as R from 'ramda';
import moment from 'moment';

import { getUI } from '../ui/selectors';
import { getUserId, getUserOrTfLocale } from '../auth/selectors';

import { REFRESH_BOOKING_UI, UPDATE_BOOKING_WITH_CASH, BOOKING_END2END_BOOKING_UI, CALCULATE_END2END_BOOKING } from './constants';

const DEFAULT_VALUE = {};
const DEFAULT_LIST = [];

const getBookingDeletingId = (_, { id }) => id;

export const domain = ({ booking }) => booking;

export const getSearch = createSelector(domain, booking => booking.get('search'));
export const getBookingsStat = R.pipe(domain, booking => booking.get('bookingsStat'));

const getBooking = createSelector(domain, booking => booking.get('booking'));

const getRateHash = R.pipe(
  domain,
  booking => booking.get('rateHash')
);

export const getBookingsPayments = R.pipe(
  getBooking,
  R.prop('payments')
);
export const getBookingSchedules = R.pipe(
  getBooking,
  R.prop('schedules')
);
export const getSoredBookingsPayments = createSelector(
  getBookingsPayments,
  R.sort((a, b) => moment(a.date_paid).diff(moment(b.date_paid)))
);
export const getBookingsCount = R.pipe(getBookingsStat, R.prop('count_bookings'));
export const getBookingSearchPage = createSelector(getSearch, ({ page }) => page);
export const getBookingAdminSearchOrder = createSelector(getSearch, ({ adminOrder }) => adminOrder);
export const getBookingUserSearchOrder = createSelector(getSearch, ({ userOrder }) => userOrder);
const getBookingSearchResults = R.pipe(getSearch, R.path(['data', 'results']));

export const getBookingDataWithTranslate = createSelector(
  [getBookingSearchResults, getUserOrTfLocale],
  (bookings, locale) => R.map(
    R.over(
      R.lensPath(['tour', 'country', 'translations']),
      R.when(
        Boolean,
        R.path([locale, 'name'])
      )
    ),
    bookings
  )
);

export const getMessagesCountUnread = createSelector(domain, booking => booking.get('messagesCountUnread'));
export const getQuery = createSelector(getSearch, ({ query }) => query);

export const getBookingInvoices = createSelector(domain, booking => booking.getIn(['booking', 'invoices'], DEFAULT_LIST));
export const getActiveBookingInvoices = createSelector(getBookingInvoices, R.filter(({ is_deleted: deleted }) => !deleted));
export const getLastBookingInvoice = createSelector(
  getActiveBookingInvoices,
  R.head
);
export const getBookingId = createSelector(domain, booking => booking.getIn(['booking', 'data', 'id']));
export const getBookingData = R.pipe(domain, booking => booking.getIn(['booking', 'data']));

export const geIsBookingFromParser = R.pipe(
  getBookingData,
  R.ifElse(
    Boolean,
    R.allPass([
      ({ filled_type: filledType }) => filledType === 1 || filledType === 3,
      R.propEq(true, 'filled_by_parser')
    ]),
    R.F
  )
);
export const getBookingViews = R.pipe(domain, booking => booking.getIn(['booking', 'views'], DEFAULT_LIST));
export const getOtherUsersViews = createSelector(
  getBookingViews,
  getUserId,
  (views, userID) => R.filter(({ id }) => id !== userID, views)
);
export const getDocumentsFromBooking = R.pipe(getBookingData, R.prop('documents'));
export const getMessagesTemplates = R.pipe(domain, booking => booking.get('templates', DEFAULT_LIST));

const getBookingClaimEntity = R.pipe(getBookingData, R.prop('claim'));
export const getBookingCash = R.pipe(getBookingData, R.prop('cash_flow'));
export const getBookingOtpuskOperator = R.pipe(getBookingData, R.path(['operator', 'otpusk_id']));

export const getBookingDate = R.pipe(getBookingData, R.prop('date'));

const getIsFinishedBooking = ({ status, op_claim_id: opClaimId }) => {
  return status !== 7 && opClaimId;
};
export const getIsShowEnd2EndBooking = R.pipe(
  getBookingData,
  booking => {
    if (!booking) return false;

    const isEnabledBooking = booking.operator.booking_type === 1 || booking.operator.booking_type === 2;

    return !isEnabledBooking
      ? false
      : !getIsFinishedBooking(booking);
  }
);

export const getBookingClaimId = R.pipe(getBookingClaimEntity, R.prop('id'));
export const getBookingMessages = R.pipe(domain, booking => booking.getIn(['booking', 'bookingMessages'], DEFAULT_LIST));

export const getBookingDocuments = R.pipe(domain, booking => booking.getIn(['booking', 'documents'], DEFAULT_LIST));
export const getEnd2EndBookingData = R.pipe(domain, booking => booking.getIn(['booking', 'end2EndBookingData']));

export const getActiveMessages = createSelector(
  getBookingMessages,
  R.filter(({ deleted }) => !deleted)
);

export const getActiveInternalMessages = createSelector(
  getActiveMessages,
  R.filter(R.prop('internal'))
);
export const getActiveExternalMessages = createSelector(
  getActiveMessages,
  R.filter(({ internal }) => !internal)
);

export const getExternalMessageWithoutMyView = R.converge(
  (messages, userID) => R.filter(
    ({ views }) => R.all(({ user: { id } }) => id !== userID, views),
    messages
  ),
  [getActiveExternalMessages, getUserId]
);

export const getUIRates = createSelector(
  R.prop('operators'),
  getRateHash,
  (operators, rateHash) => operators.getIn(['ui', rateHash], DEFAULT_VALUE)
);
export const getRates = createSelector(
  R.prop('operators'),
  getRateHash,
  (operators, rateHash) => operators.getIn(['rates', rateHash], DEFAULT_VALUE)
);

const toFixedRate = rate => Number(rate.toFixed(4));
const DATE_FORMAT = 'YYYY-MM-DD';

export const getTodayRatesByOperator = createSelector(
  [
    getRates,
    getBookingOtpuskOperator
  ],
  (rates, operatorId) => {
    const rateByToday = rates[moment().format(DATE_FORMAT)];

    if (!rateByToday) {
      return DEFAULT_VALUE;
    }

    return R.map(ratesByDate => {
      const rate = ratesByDate[operatorId]?.rate;

      return rate ? toFixedRate(rate) : 1;
    }, rateByToday);
  }
);

export const getBookingDeletingUI = createSelector(
  getUI, getBookingDeletingId, (ui, id) => ui[id] || DEFAULT_VALUE
);
export const getBookingMessagesUi = R.partialRight(getUI, [{ path: ['booking', 'bookingMessages'] }]);
export const getUpdateBookingWithCashUI = R.partialRight(getUI, [{ key: UPDATE_BOOKING_WITH_CASH }]);
export const getRefreshBookingUI = R.partialRight(getUI, [{ path: REFRESH_BOOKING_UI }]);
export const getBookingEnd2EndBookingUI = R.partialRight(getUI, [{ key: BOOKING_END2END_BOOKING_UI }]);
export const getCalculateEnd2EndBookingUI = R.partialRight(getUI, [{ key: CALCULATE_END2END_BOOKING }]);
