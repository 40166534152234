import { createSelector } from 'reselect';
import moment from 'moment';
import * as R from 'ramda';

import { getUI } from 'bus/ui/selectors';

import { EUR_CURRENCY, USD_CURRENCY } from '../../helpers/currency';

import { findCurrencyFromName } from './stepers/helpers';

import { getLocale, getTfScenarios, getUserLocale } from '../auth/selectors';

import { getFields } from '../documentFields/selectors';

import { GET_CLAIM_SIMPLIFIED_UI } from './constants';

const EMPTY_LIST = [];
const EMPTY_OBJ = {};
const EXCLUDE_CURRENCY = [USD_CURRENCY.code, EUR_CURRENCY.code];

const domain = ({ clientClaim }) => clientClaim;

export const getClient = createSelector(domain, clientClaim => clientClaim.get('client').toObject());

export const getClientId = createSelector(getClient, ({ id }) => id);
export const getClienFullPhones = createSelector(
  getClient, ({ fullphones }) => fullphones || EMPTY_LIST
);

export const getClientClaimsCount = R.pipe(
  getClient,
  R.prop('claims'),
  R.length
);

export const getClaim = createSelector(domain, clientClaim => clientClaim.get('claim') || EMPTY_OBJ);

export const getIsSetClientAndClaim = createSelector(
  [getClient, getClaim],
  (client, claim) => !R.isEmpty(claim) && !R.isEmpty(client)
);

export const getSimplifiedClaim = R.pipe(domain, clientClaim => clientClaim.get('simplifiedClaim'));
export const getActiveClaimStep = R.pipe(domain, clientClaim => clientClaim.get('activeClaimStep'));
export const getClaimSimplifyUI = R.partialRight(getUI, [{ key: GET_CLAIM_SIMPLIFIED_UI }]);

export const getClaimId = createSelector(getClaim, ({ id }) => id);
export const getClaimBooking = R.pipe(getClaim, R.prop('booking'));
export const getClaimBookingId = R.pipe(getClaimBooking, R.prop('id'));
export const getHash = createSelector(getClaim, ({ hash }) => hash);
export const getClosingReason = createSelector(
  getClaim, ({ closing_reason: closingReason }) => closingReason
);
export const getClosingReasonWithTranslate = createSelector(
  [getClosingReason, getUserLocale, getLocale],
  (reason, userLocale, tfLocale) => reason && R.call(
    R.pipe(
      R.find(({ locale }) => userLocale === locale),
      translation => translation || R.find(({ locale }) => locale === tfLocale, R.prop('translations', reason))
    ), reason.translations
  )
);

export const getClaimActive = createSelector(getClaim, ({ active }) => active);
export const getScenario = createSelector(getClaim, R.prop('scenario'));
export const getScenarioId = R.pipe(getScenario, R.prop('id'));
export const getScenarioName = R.pipe(getScenario, R.prop('name'));
const getStep = R.pipe(getClaim, R.prop('step'));
export const getStepMarket = R.pipe(getStep, R.prop('marker'));

export const getDoneSteps = R.pipe(getClaim, R.prop('done_steps'));
export const getFieldsByScenario = createSelector([getScenarioId, getTfScenarios, getFields],
  (scenarioId, scenarios, fields) => {
    const scenario = R.find(({ id }) => id === scenarioId, scenarios);

    if (scenario) {
      const { config_id: config } = scenario;
      const enabledField = `contract_${config}`;

      return fields
        .filter(({ object }) => enabledField === object)
        .sort((a, b) => a.order - b.order);
    }

    return EMPTY_LIST;
  });

export const getEpassports = createSelector(getClaim, ({ epassports }) => epassports);
export const getClaimCountry = createSelector(getClaim, ({ country }) => country);
export const getDateEndOfClaim = createSelector(getClaim,
  ({ date_start: date, length }) => date && moment(date).add(length, 'days').format('YYYY-MM-DD')
);

export const isCommentShow = createSelector(domain, clientClaim => clientClaim.get('isCommentShow'));
export const getTouchedClaim = createSelector(domain, clientClaim => clientClaim.get('touchedClaim'));
export const getDeclarations = createSelector(domain, clientClaim => clientClaim.get('declarations'));
export const getDigitalContract = createSelector(domain, clientClaim => clientClaim.get('digitalContract'));
export const isEditingContract = createSelector(getDigitalContract, contract => R.prop('contract_number', contract));
export const getShortLink = createSelector(getDigitalContract, contract => R.prop('short_url', contract));

export const getRealEpassports = createSelector(getClaim, ({ epassports }) => epassports.filter(passport => !R.prop('isNew', passport)));

export const getStaticLists = createSelector(domain, clientClaim => clientClaim.get('staticLists'));
export const getRooms = createSelector(getStaticLists, staticLists => staticLists.get('rooms', EMPTY_LIST));
export const getRoomByName = R.converge(
  (rooms, roomName) => R.find(({ name }) => roomName.toLowerCase() === name.toLowerCase(), rooms),
  [getRooms, (_, { name }) => name]
);
export const getCurrencies = createSelector(getStaticLists, staticLists => staticLists.get('currencies', EMPTY_LIST));

export const sortedCurrencyList = createSelector([getCurrencies], currencies => (currencies.length
  ? [
    findCurrencyFromName(USD_CURRENCY.code, currencies),
    findCurrencyFromName(EUR_CURRENCY.code, currencies),
    ...currencies.filter(({ value }) => !EXCLUDE_CURRENCY.includes(value))
  ] : EMPTY_LIST)
);

export const getClaimOffers = createSelector(
  getClaim,
  R.prop('offers')
);
